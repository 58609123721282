<template>
  <div class="scheme-contain">
    <div class="search-header">
      <div class="search-item">
        <span>本月进店：{{ thisMonth }} 次</span>
      </div>
      <div class="search-item">
        <span>上月进店：{{ lastMonth }} 次</span>
      </div>
      <div class="search-item">
        <span>打卡地址：</span>
        <Select clearable v-model="searchData.dkdz" placeholder="请选择..." style="width: 240px;">
          <Option :value="item" v-for="(item, index) in punchAddresses" :key="index">{{ item }}</Option>
        </Select>
      </div>
      <div class="search-item">
        <span>打卡时间：</span>
        <DatePicker type="daterange" placeholder="请选择..." style="width: 240px" @on-change="handleChangeDate">
        </DatePicker>
      </div>
      <div class="search-item">
        <Button type="primary" @click="handleSearch">搜索</Button>
      </div>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="data"></Table>
      <Row>
        <Col span="24">
        <span class="records">共 {{ sizeTotal }} 条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="sizeTotal" :current="searchData.page" @on-change="handleChangePage"></Page>
      </div>
    </div>
  </div>
</template>

<script>
import punchService from '@/services/punchService';

export default {
	data() {
		return {
			data: [],
			columns: [
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{ title: '姓名', key: 'xm', align: 'center' },
				{ title: '进店时间', key: 'jdsj', align: 'center' },
				{ title: '打卡地址', key: 'dkdz', align: 'center' },
				{ title: '照片', key: 'rq', align: 'center' },
				{ title: '定位', key: 'rq', align: 'center' },
			],
			sizeTotal: 0,
			searchData: {
				page: 1,
				size: 10,
				dkdz: '',
				start_time: '',
				end_time: '',
				member_id: '',
			},
			punchAddresses: [],
			thisMonth: 0,
			lastMonth: 0,
		};
	},
	methods: {
		handleChangeDate(date) {
			this.searchData.start_time = date[0];
			this.searchData.end_time = date[1];
		},
		getPunchAddresses() {
			punchService.getPunchAddresses().then((data) => {
				this.punchAddresses = data;
			});
		},
		getPunchList() {
			punchService.getPunchList(this.searchData).then((data) => {
				this.data = data.list;
				this.sizeTotal = data.row_size;
			});
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.getPunchList();
		},
		handleSearch() {
			this.searchData.page = 1;
			this.getPunchList();
		},
		getPunchStatistics() {
			punchService.getPunchStatistics({ member_id: this.searchData.member_id }).then((data) => {
				this.thisMonth = data.byjd;
				this.lastMonth = data.syjd;
			});
		},
	},
	mounted() {
		this.searchData.member_id = this.$route.params.member_id;
		this.getPunchStatistics();
		this.getPunchList();
		this.getPunchAddresses();
	},
};
</script>

<style scoped>
.search-header {
  display: flex;
  gap: 40px;
  padding: 0 20px 20px;
  align-items: center;
}
.search-item {
  display: flex;
  align-items: center;
}
</style>
